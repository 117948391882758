import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ProfilePicture from 'routes/app/components/ProfilePicture.jsx'; // Import ProfilePicture component

const ChatHeader = ({ currentChat }) => {    
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleClick = () => {
    return true;
    // window.open("/profile/"+currentChat.partnerUID,"_blank")
    // setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="relative w-full">
        <Link to={"/profile/"+currentChat.partnerUID}>
      <div
        className="bg-blue-500 p-4 text-white flex justify-between items-center cursor-pointer"
        onClick={handleClick}
      >
        <div className="flex items-center">
          {/* Display the current chat name or fallback to partner name */}
          <ProfilePicture userId={currentChat.partnerUID} small={true} />
          <h3 className="text-lg font-bold">
            {currentChat?.name ? currentChat.name : `${currentChat?.partnerName || 'Loading...'}`}
          </h3>
        </div>
        {/* <span className="text-sm">Click for more chats</span> */}
      </div>
      </Link>
      {isDropdownOpen && (
        <div className="relative w-full">
          {/* Add chat switching logic here if needed */}
        </div>
      )}
    </div>
  );
};

export default ChatHeader;
