// src/utils/firebaseUtils.js
import { getStorage, ref, listAll, uploadBytes } from 'firebase/storage';
import Config from 'assets/Config';

export const fetchUserImages = async (userId) => {
    const storage = getStorage();
    const folderRef = ref(storage, `users/${userId}/pictures`);
    const pictures = [];

    try {
        const result = await listAll(folderRef);
        const urls = result.items.map((itemRef) => {
            const timestamp = itemRef.name.split('.')[0]; // Extract timestamp from filename
            const url = `${Config.storageDomain}/users/${userId}/pictures/${itemRef.name}`;
            return { url, timestamp: parseInt(timestamp, 10) }; // Store URL with parsed timestamp
        });

        // Sort images by timestamp in ascending order
        urls.sort((a, b) => a.timestamp - b.timestamp);

        // Ensure the array has 10 slots, filling remaining with null
        urls.forEach(({ url }) => pictures.push(url));
        while (pictures.length < 10) pictures.push(null);

        return pictures;
    } catch (error) {
        console.error("Error fetching images:", error);
        return Array(10).fill(null); // Return placeholders if there's an error
    }
};

export const resizeAndUploadImage = async (file, userId) => {
    const storage = getStorage();
    const timestamp = Date.now(); // Use current timestamp for unique file naming
    const pictureRef = ref(storage, `users/${userId}/pictures/${timestamp}.jpg`);

    // Create an image and canvas for resizing
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    return new Promise((resolve, reject) => {
        img.onload = async () => {
            // Calculate the new dimensions to fit within 700px, maintaining aspect ratio
            const maxSide = 700;
            let width = img.width;
            let height = img.height;

            if (width > maxSide || height > maxSide) {
                if (width > height) {
                    height = (height * maxSide) / width;
                    width = maxSide;
                } else {
                    width = (width * maxSide) / height;
                    height = maxSide;
                }
            }

            // Set canvas dimensions and draw the resized image onto it
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            // Convert the canvas to a blob in JPEG format
            canvas.toBlob(async (blob) => {
                if (blob) {
                    try {
                        await uploadBytes(pictureRef, blob, { contentType: 'image/jpeg' });
                        const url = `${Config.storageDomain}/users/${userId}/pictures/${timestamp}.jpg`;
                        resolve(url);
                    } catch (error) {
                        reject(error);
                    }
                } else {
                    reject(new Error('Canvas conversion to blob failed'));
                }
            }, 'image/jpeg', 0.8); // Adjust quality if needed
        };

        img.onerror = reject;
        img.src = URL.createObjectURL(file); // Load the image from the file input
    });
};
