import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import TermsCont from 'routes/pre/pages/TermsCont.jsx';

const TermsForm = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleCheckboxChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);

    const auth = getAuth();
    const user = auth.currentUser;
    const db = getFirestore();

    if (!user) {
      setError("No user is logged in.");
      setSubmitting(false);
      return;
    }

    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { termsAccepted });
      window.open('/','_self');
      console.log('Terms accepted status updated successfully.');
    } catch (err) {
      console.error('Error updating terms accepted status:', err);
      setError('Failed to update your acceptance of the terms.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto w-[90%] max-w-md mb-10">
      <div className='w-full h-[400px] overflow-y-scroll shadow-lg rounded-lg p-6 bg-base-100'>
        <TermsCont />
      </div>

      <form onSubmit={handleSubmit} className="mt-4 text-center">
        <div className="form-control mb-4">
          <label className="flex flex-row justify-center items-center cursor-pointer">
            <input
              type="checkbox"
              className="checkbox checkbox-primary"
              checked={termsAccepted}
              onChange={handleCheckboxChange}
              disabled={submitting}
            />
            <span className="text-lg ml-2">I accept the terms and conditions</span>
          </label>
        </div>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <button
          type="submit"
          className={`btn btn-primary ${submitting ? 'loading' : ''}`}
          disabled={!termsAccepted || submitting}
        >
          {submitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default TermsForm;
