import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { NotificationsProvider } from 'utils/NotificationsProvider';
import ScrollToTop from "utils/ScrollToTop";
import Footer from 'components/Footer';
import FooterRoutes from 'routes/FooterRoutes';

import Header from './components/Header';
import Home from './pages/Home/Home';
import MyTrips from './pages/MyTrips/MyTrips';
import TripCreate from './pages/TripCreate/TripCreate';
import TripDetails from './pages/TripDetails/TripDetails';
import SearchResults from './pages/SearchResults/SearchResults';
import MatchView from './pages/MatchView/MatchView';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import EditProfilePage from './pages/EditProfilePage/EditProfilePage';
import Settings from './pages/Settings/Settings';
import ReviewPage from './pages/ReviewPage/ReviewPage';
import ChatList from './pages/chat/ChatList';
import ChatRoom from './pages/chat/ChatRoom';

const WebApp = ()=>{
    return(
        <NotificationsProvider>
            <Router>
                <ScrollToTop />
                <div className="relative min-h-screen flex flex-col bg-gray-100">
                    <Header isLoggedIn={true} hasUnreadMessages={true} />
                    <div className="flex-1 flex flex-col h-full">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/my-profile" element={<ProfilePage />} />
                            <Route path="/my-profile/:reviewID" element={<ProfilePage />} />
                            <Route path="/edit-profile" element={<EditProfilePage />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/profile/:id" element={<ProfilePage />} />
                            <Route path="/my-trips" element={<MyTrips />} />
                            <Route path="/search-results/:tripId" element={<SearchResults />} />
                            <Route path="/create-trip" element={<TripCreate />} />
                            <Route path="/match/:tripID/:matchID" element={<MatchView />} />
                            <Route path="/trip-details/:tripId" element={<TripDetails />} />
                            <Route path="/chats/:chatId" element={<ChatRoom />} />
                            <Route path="/chats/user/:partnerUID" element={<ChatRoom />} />
                            <Route path="/chats" element={<ChatList />} />
                            <Route path="/review/:userId" element={<ReviewPage />} />
                            {FooterRoutes}
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </div>
                    <Footer />
                </div>
            </Router>
        </NotificationsProvider>
    )
}

export default WebApp