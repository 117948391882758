import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'; // Import Firebase auth methods
import EmailField from './EmailField';
import PasswordField from './PasswordField';
import GoogleSignIn from './GoogleSignIn'; 
import ForgotPassword from './ForgotPassword';
import Loader from 'components/Loader';

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    const [error, setError] = useState(null); // To display any errors
    const [loading, setLoading] = useState(false); // To manage loading state

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        
        const auth = getAuth();

        try {
            const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
            // Redirect or handle successful login here
        } catch (error) {
            setError(error.message); // Set error to display to the user
            setLoading(false);
        }
    }

    return (
        <div className="flex items-center justify-center bg-gray-100">
            <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
                <h2 className="text-2xl font-semibold text-center mb-6">Login</h2>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>} {/* Show error if there is one */}
                <form onSubmit={handleSubmit}>
                    {/* Email Field */}
                    <EmailField
                        email="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        disabled={loading} // Disable input during sign-in
                    />

                    {/* Password Field */}
                    <PasswordField
                        password="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        disabled={loading} // Disable input during sign-in
                    />

                    {/* Submit Button */}
                    <div className="flex justify-center">
                        <button type="submit" className="btn btn-primary w-full" disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'}
                        </button>
                    </div>
                </form>

                {/* Forgot Password - Pass the email from the form as a prop */}
                <ForgotPassword initialEmail={formData.email} />

                {/* Google Sign In */}
                <GoogleSignIn setLoading={setLoading} setError={setError}/>
                
                {loading && <Loader/>}
            </div>
        </div>
    )
}

export default Login;
