import React from 'react';
import TermsCont from 'routes/pre/pages/TermsCont'
const Terms = () => {
  return (
    <div className="p-6 max-w-4xl mx-auto bg-base-100 shadow-lg rounded-lg m-5">
        <TermsCont/>
    </div>
  );
};

export default Terms;
