import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import ProfilePicture from "routes/app/components/ProfilePicture";
import StarRating from './StarRating';

const ReviewItem = ({ review, reviewID }) => {
    
    const navigate = useNavigate()
    const [reviewerName, setReviewerName] = useState('');
    const db = getFirestore();

    // Fetch the reviewer's name using the userId
    useEffect(() => {
        const fetchReviewerName = async () => {
        try {
            const userDoc = await getDoc(doc(db, 'users', review.userId));
            if (userDoc.exists()) {
            const userData = userDoc.data();
            setReviewerName(userData.name || 'Anonymous');
            } else {
            setReviewerName('Anonymous');
            }
        } catch (error) {
            console.error('Error fetching reviewer name: ', error);
            setReviewerName('Anonymous');
        }
        };

        fetchReviewerName();
    }, [db, review.userId])

    // Function to render review text with line breaks
    const renderReviewTextWithLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
        ));
    }

    return (
        <div className={"p-4 bg-slate-100 shadow-lg rounded-lg "+((reviewID==review.id)?"border-2 border-red-500":"")}>
            {/* Reviewer Name */}
            <div className='flex flex-row items-center mb-4 cursor-pointer' onClick={()=>{navigate("/profile/"+review.userId)}}>
                <ProfilePicture userId={review.userId} small={true}/>
                <span className="font-bold text-lg">{reviewerName}</span>
            </div>
            {/* Star Rating */}
            <div className="flex items-center mb-2">
                <StarRating averageRating={review.rating} size={20}/>
                <span className="ml-2 text-base font-semibold">{review.rating?.toFixed(1) || ''}</span>
            </div>

            {/* Review Text with Line Breaks */}
            <p className="text-gray-700 mb-2">
                {renderReviewTextWithLineBreaks(review.reviewText)}
            </p>

            {/* Review Timestamp */}
            <p className="text-sm text-gray-500">
                {new Date(review.timestamp.seconds * 1000).toLocaleDateString()} at {new Date(review.timestamp.seconds * 1000).toLocaleTimeString()}
            </p>
        </div>
    )
};

export default ReviewItem;