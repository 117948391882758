import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { getAuth, GoogleAuthProvider, signInWithCredential } from "firebase/auth";
import googleImg from 'assets/images/auth/google.svg';

const GoogleSignIn = ({setLoading, register, setError}) => {
    const handleLoginSuccess = async(response) => {
        try{
            const accessToken = response['access_token'];
            const credential = GoogleAuthProvider.credential(null, accessToken);
            const userCredential = await signInWithCredential(getAuth(), credential); //https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithcredential
            const uid = userCredential.user.uid;
        }
        catch(err){
            setError(err.message)
            setLoading(false);
        }
    }

    const handleLoginFailure = (err) => {
        setError(err.message);
        setLoading(false);
    };

    const login = useGoogleLogin({
        onSuccess: handleLoginSuccess,
        onError: handleLoginFailure
    });

    const handleGoogle = ()=>{
        setLoading(true);
        login();
    }

    return (
        <>
            {/* Divider */}
            <div className="flex items-center justify-center my-4">
                <hr className="w-full border-t border-gray-300" />
                <span className="mx-4 text-gray-500">OR</span>
                <hr className="w-full border-t border-gray-300" />
            </div>

            {/* Google Sign In Button */}
            <button className="flex flex-row btn btn-outline w-full" onClick={handleGoogle}>
                <img src={googleImg} className='h-[20px]'/>
                {register?"Register":"Sign in"} with Google
            </button> 
        </>
    )
}

export default GoogleSignIn;
