import { Route } from 'react-router-dom';

import Privacy from 'generalPages/Privacy';
import Terms from 'generalPages/Terms';
import CookiePolicy from 'generalPages/CookiePolicy';
import ContactForm from "generalPages/Contact";

const FooterRoutes = [
    <Route key='terms' path="/terms" element={<Terms />} />,
    <Route key='privacy' path="/privacy-policy" element={<Privacy />} />,
    <Route key='cookie' path="/cookie-policy" element={<CookiePolicy />} />,
    <Route key='contact' path="/contact" element={<ContactForm />} />,
]

export default FooterRoutes;