// Notifications.js
import { getFirestore, doc, writeBatch } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNotifications } from 'utils/NotificationsProvider';
import NotificationItem from './NotificationItem'; // Import the NotificationItem component
import Loader from 'components/Loader'

const Notifications = () => {
    const { notifications, loading } = useNotifications();
    
    const auth = getAuth();
    const db = getFirestore();
    const currentUser = auth.currentUser; // Get the currently logged-in user

    // Mark all notifications as read
    const markAllAsRead = async () => {
        if (currentUser) {
          const batch = writeBatch(db); // Create a batch operation
          notifications.forEach((notification) => {
            const notificationRef = doc(db, 'users', currentUser.uid, 'notifications', notification.id);
            batch.delete(notificationRef); // Delete each notification instead of updating it
          });
          await batch.commit(); // Commit the batch to delete all notifications
        }
    }

    if (loading) return <Loader/>

    return (
        <div className="p-6">
            <div className="flex flex-col justify-center items-center space-y-2">
                <h3 className="text-2xl font-bold">Notifications</h3>

                {notifications.length > 0 && <button
                    className="btn btn-sm btn-primary"
                    onClick={markAllAsRead}
                >
                    Mark All as Read
                </button>}
            </div>
            <div className="mt-4 space-y-2">
                {notifications.length === 0 ? (<p className="text-sm text-gray-500 text-center">No notifications.</p>) : (
                    notifications.map((notification) => (
                        <NotificationItem
                            key={notification.id}
                            notification={notification}
                        />
                    ))
                )}
            </div>
        </div>
    )
};

export default Notifications;