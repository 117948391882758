import Config from './assets/Config';
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase Auth
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Firestore imports

import Loading from "components/Loading";
import PreRoutesLayout from 'routes/pre/PreRoutes';
import WebApp from 'routes/app/AppRoutes';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [redirectToEditProfile, setRedirectToEditProfile] = useState(false); // New state
    const resetPass = (window.location.pathname === '/reset-password')

    const checkUserProfile = async (user) => {
        const db = getFirestore(); // Initialize Firestore
        const userDocRef = doc(db, 'users', user.uid);
        
        try {
            const userDoc = await getDoc(userDocRef);
            if(userDoc.exists()){
                setRedirectToEditProfile(!userDoc.data().init);
                setTermsAccepted(userDoc.data().termsAccepted);
            }
            else{
                setTermsAccepted(false);
                setRedirectToEditProfile(true);
            }
        } catch (err) {
            console.error("Error fetching user document:", err);
        }
    };

    const init = async () => {
        await Config.init(); // Initialize your config    
        const auth = getAuth(); // Initialize Firebase auth
        
        // Listen to the user's authentication state
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log("uid: "+user.uid);    
                // Check email verification            
                setIsEmailVerified(user.emailVerified);
                if(user.emailVerified) await checkUserProfile(user); // Check if the user profile exists
                setIsLoggedIn(true); // User is logged in
            } else {
                setIsLoggedIn(false); // User is logged out
            }
            setLoading(false); // Stop loading once the auth state is determined
        });
    };

    useEffect(() => {
        init();
    }, []);

    if (loading) return <Loading/>
    else if(resetPass || !isLoggedIn || (isLoggedIn && !isEmailVerified) || redirectToEditProfile || !termsAccepted){
        return <PreRoutesLayout resetPass={resetPass} isLoggedIn={isLoggedIn} isEmailVerified={isEmailVerified} redirectToEditProfile={redirectToEditProfile} termsAccepted={termsAccepted}/>
    }
    else return <WebApp/>
}

export default App;
