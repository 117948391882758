import React, { useRef } from 'react';
import ProfilePicture from "routes/app/components/ProfilePicture"

const ImageUploader = ({ onImageSelect, disabled, userId, count }) => {    
  const fileInputRef = useRef();

  // Handle profile image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onImageSelect(reader.result); // Pass the selected image to parent component
      };
      reader.readAsDataURL(file);
    }
  };

  // Trigger the file input when the image is clicked
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="relative flex flex-col items-center">
        <div className='relative flex flex-col justify-center items-center cursor-pointer' onClick={handleImageClick}>
            <div className='absolute z-20 w-full h-full rounded-full bg-blue-950 bg-opacity-40 flex justify-center items-center'>
                <div className='text-center text-xl text-white'>
                    Change<br />Picture
                </div>
            </div>
            <ProfilePicture userId={userId} count={count}/>
        </div>
      <input
        type="file"
        ref={fileInputRef} // Reference to the hidden file input
        style={{ display: 'none' }} // Hide the actual file input
        accept="image/*" // Only accept image files
        onChange={handleImageChange} // Handle file selection
        disabled={disabled} // Disable if necessary
      />
    </div>
  );
};

export default ImageUploader;
