import React from 'react';
import { Link } from 'react-router-dom';
import ProfilePicture from 'routes/app/components/ProfilePicture.jsx'; // Import ProfilePicture component

const ChatListItem = ({ chat, index }) => {
  return (
    <Link key={chat.id} to={`/chats/${chat.id}`} className="block">
      <li
        className={`relative py-4 px-10 ${
          index % 2 === 0 ? 'bg-gray-300' : 'bg-gray-200'
        } hover:bg-blue-500 hover:text-white transition-colors`} // Alternate background colors + hover effect
      >
        <div className="flex items-center space-x-4">
          {/* Display Profile Pictures of other participants */}
          {chat.otherParticipants.map((participantId) => (
            <ProfilePicture key={participantId} userId={participantId} small={true} />
          ))}

          {/* Show participant names instead of UIDs */}
          <div className="font-semibold">
            {chat.participantNames.join(', ')}
          </div>

          {/* Conditionally render the red dot if there are unread messages */}
          {chat.hasUnreadMessages && (
            <span className="flex-shrink-0 w-3 h-3 rounded-full bg-red-600 ml-4"></span>
          )}
        </div>
      </li>
    </Link>
  );
};

export default ChatListItem;
