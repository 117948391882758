import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, getDocs, query, orderBy } from 'firebase/firestore';
import Loader from 'components/Loader'; // Assuming you have a Loader component for loading states
import ReviewItem from './ReviewItem'; // Import the new ReviewItem component
import StarRating from './StarRating';

const RatingScore = ({ averageRating, totalReviews }) => {   
    return (
      <div className="flex items-center space-x-4">
        {/* Average Rating */}
        <StarRating averageRating={averageRating} size={24} />
        <span className="ml-2 text-lg font-semibold">{averageRating?.toFixed(1) || ''}</span>
  
        {/* Total Reviews */}
        <div className="text-gray-500">
          ({totalReviews || 0} review{totalReviews !== 1 ? 's' : ''})
        </div>
      </div>
    );
  };

const UserReviews = ({ uid, reviewID }) => {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const reviewRefs = useRef({}); // Create a ref object to store the refs for each ReviewItem

  const db = getFirestore();

  useEffect(() => {
    const fetchReviews = async () => {
      setIsLoading(true);
      try {
        const reviewsRef = collection(db, 'users', uid, 'reviews');
        const reviewsQuery = query(reviewsRef, orderBy("timestamp", "desc"));
        const reviewsSnapshot = await getDocs(reviewsQuery);
        const reviewsData = reviewsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        
        setReviews(reviewsData);
      } catch (error) {
        console.error('Error fetching reviews: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, [db, uid]);

  // Scroll to the review with matching reviewID after reviews are fetched
  useEffect(() => {
    if (reviewID && reviewRefs.current[reviewID]) {
      reviewRefs.current[reviewID].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [reviews, reviewID, isLoading]); // This will trigger the scroll when reviews or reviewID changes

  // Calculate total reviews and average rating
  const totalReviews = reviews.length;
  const averageRating = totalReviews > 0 
    ? reviews.reduce((sum, review) => sum + review.rating, 0) / totalReviews 
    : 0;

  if (isLoading) {
    return <Loader />; // Display loading spinner while fetching reviews
  }

  return (
    <>
    <RatingScore averageRating={averageRating} totalReviews={totalReviews} />
    <div className="container mx-auto mt-4 p-4">
      <h2 className="text-2xl font-bold mb-4">User Reviews</h2>      

      {reviews.length === 0 ? (
        <p>No reviews yet.</p>
      ) : (
        <div className="space-y-4">
          {reviews.map((review) => (
            <div
              key={review.id}
              ref={(el) => (reviewRefs.current[review.id] = el)} // Store each review element reference
            >
              <ReviewItem review={review} reviewID={reviewID} />
            </div>
          ))}
        </div>
      )}
    </div>
    </>
  );
};

export default UserReviews;
