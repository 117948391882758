import React from 'react';
import { getTimeString } from 'utils/timeUtils';
import { Link } from 'react-router-dom';

const TripItem = ({ tripData }) => {    
  const timeString = getTimeString(tripData);

  return (
    <li className="mb-6 p-4 border rounded-lg shadow-sm">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        {/* Trip Details */}
        <div className="mb-4 md:mb-0 w-[450px] max-w-full">
          <h3 className="text-lg md:text-xl font-bold">
            {tripData.startLocationName} → {tripData.endLocationName}
          </h3>
          <p className="text-gray-700">
            {timeString}
          </p>
        </div>

        {/* Buttons */}
        <div className="flex flex-col space-y-2 w-full md:w-auto md:flex-row md:space-x-4 md:space-y-0">
          {/* "See Matches" Button */}
          <Link
            to={`/search-results/${tripData.id}`}
            className="btn btn-primary w-full md:w-auto"
          >
            Find Matches
          </Link>

          {/* "View Details" Button */}
          <Link
            to={`/trip-details/${tripData.id}`}
            className="btn btn-outline w-full md:w-auto"
          >
            View Details
          </Link>
        </div>
      </div>
    </li>
  );
};

export default TripItem;
