// src/components/PictureSlot.js
import React, { useRef, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { resizeAndUploadImage } from 'utils/imageUtils';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import Loader from 'components/Loader';

const PictureSlot = ({ url, isNext, userId, onUploadComplete, onDeleteComplete }) => {
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const fileInputRef = useRef(null);
  const storage = getStorage();

  const handleSlotClick = () => {
    if (url) {
      setShowDeleteConfirmation(true); // Show delete confirmation overlay if there's an image
    } else if (isNext && fileInputRef.current) {
      fileInputRef.current.click(); // Open file input dialog if it's the next slot
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file || !isNext) return;

    setUploading(true);
    try {
      const uploadedUrl = await resizeAndUploadImage(file, userId);
      onUploadComplete(uploadedUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
    setUploading(false);
  };

  const handleDelete = async () => {
    if (!url) return;

    setDeleting(true);
    try {
      const fileName = url.split('/').pop();
      const imageRef = ref(storage, `users/${userId}/pictures/${fileName}`);
      await deleteObject(imageRef);
      onDeleteComplete(url);
    } catch (error) {
      console.error("Error deleting image:", error);
    } finally {
      setDeleting(false);
      setShowDeleteConfirmation(false);
    }
  };

  const handleCloseOverlay = () => setShowOverlay(false);

  return (
    <div>
      <div
        className={"relative w-24 h-24 border border-gray-300 rounded-lg flex items-center justify-center bg-gray-100 "+((isNext || url)?"cursor-pointer":"")}
        onClick={handleSlotClick}
      >
        {url ? (
          <img src={url} alt="Profile Picture" className="w-full h-full rounded-lg object-cover" />
        ) : (
          <div className={`text-2xl text-gray-400 ${isNext ? 'text-primary' : ''}`}>
            {isNext && !uploading ? <AiOutlinePlus /> : ''}
          </div>
        )}
        {(uploading || deleting) && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 rounded-lg">
            <Loader />
          </div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </div>

      {/* Delete Confirmation Overlay */}
      {showDeleteConfirmation && !deleting && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={() => setShowDeleteConfirmation(false)}
        >
          <div
            className="bg-white p-4 rounded-lg shadow-lg text-center"
            onClick={(e) => e.stopPropagation()}
          >
            <p className="text-lg mb-4">Do you want to delete this picture?</p>
            <div className="flex justify-center gap-4">
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              >
                Yes
              </button>
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Full-size Image Overlay */}
      {showOverlay && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={handleCloseOverlay}
        >
          <div
            className="relative"
            onClick={(e) => e.stopPropagation()}
          >
            <img src={url} alt="Full size" className="max-w-full max-h-full rounded-lg" />
            <button
              onClick={handleCloseOverlay}
              className="absolute top-2 right-2 text-white text-2xl"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PictureSlot;
