// src/components/Gallery.js
import React, { useState, useEffect } from 'react';
import { fetchUserImages } from 'utils/imageUtils';

const Gallery = ({ userId }) => {
  const [pictures, setPictures] = useState([]); // Holds only the available images
  const [selectedImage, setSelectedImage] = useState(null); // Tracks the image selected for full-size view

  useEffect(() => {
    const init = async () => {
      const fetchedPictures = await fetchUserImages(userId);
      setPictures(fetchedPictures.filter(Boolean)); // Remove any null values
    };

    init();
  }, [userId]);

  const handleImageClick = (url) => setSelectedImage(url);
  const handleCloseOverlay = () => setSelectedImage(null);

  return (
    <>
      <div className="flex flex-wrap gap-4 my-10">
        {pictures.map((url, index) => (
          url && (
            <div
              key={url} // Use url as key since it's unique
              className="relative w-24 h-24 border border-gray-300 rounded-lg flex items-center justify-center bg-gray-100 cursor-pointer"
              onClick={() => handleImageClick(url)}
            >
              <img src={url} alt={`Picture ${index + 1}`} className="w-full h-full rounded-lg object-cover" />
            </div>
          )
        ))}
      </div>
      {/* Full-size Image Overlay */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={handleCloseOverlay} // Close overlay when background is clicked
        >
          <div
            className="relative"
            onClick={(e) => e.stopPropagation()} // Prevent close when image is clicked
          >
            <img src={selectedImage} alt="Full size" className="max-w-full max-h-full rounded-lg" />
            <button
              onClick={handleCloseOverlay}
              className="absolute top-2 right-2 text-white text-2xl"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Gallery;
