import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import MapComponent from './components/MapComponent'; // Assuming you have a MapComponent
import TitleComponent from './components/TitleComponent';
import SavedMatches from './components/SavedMatches'; // Import the SavedMatches component
import Loader from 'components/Loader';
import DescriptionComponent from './components/DescriptionComponent'; // Import the new Description Component
import DeleteButton from './components/DeleteButton';

const TripDetails = ({ matchData, ownData }) => {
    const { tripId } = useParams(); // Get the tripId from the URL params
    const [loading, setLoading] = useState(true);
    const [tripData, setTripData] = useState();
    const db = getFirestore(); // Initialize Firestore

    useEffect(() => {
        const fetchTripData = async () => {
            setLoading(true);
            try {
                const tripDocRef = doc(db, 'trips', tripId); // Get a reference to the document
                const tripDoc = await getDoc(tripDocRef); // Fetch the document

                if (tripDoc.exists()) {
                    const data = {
                        ...tripDoc.data(),
                        id: tripDoc.id
                    }
                    setTripData(data); // Set trip data from Firestore
                } else {
                    console.error('No such trip exists!');
                }
            } catch (error) {
                console.error('Error fetching trip data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (matchData) {
            // If matchData is passed as a prop, use it
            setTripData(matchData);
            setLoading(false);
        }
        else if(ownData){
            setTripData(ownData);
            setLoading(false);
        }
        else fetchTripData();
    }, [tripId, matchData, db]); // Dependencies

    if (loading) return <Loader />;

    return (
        <div className={"mx-auto w-full max-w-[800px] "+(ownData ? "p-2" : "p-8")}>
            <div className={"bg-white w-full " + (!matchData && "shadow-lg p-6 rounded")}>
                {tripData ? (
                    <>
                        <TitleComponent tripData={tripData} ownData={ownData} />
                        
                        {/* Description Component */}
                        {tripData.description && <DescriptionComponent description={tripData.description} />}

                        {!matchData && (
                            <>
                                <div className="mb-8">
                                    <MapComponent
                                        startCoords={tripData.startCoords}
                                        endCoords={tripData.endCoords}
                                        startRadius={tripData.startRadius}
                                        endRadius={tripData.endRadius}
                                    />
                                </div>

                                <SavedMatches tripData={tripData} />

                                <div className="flex justify-center mt-6 space-x-6">
                                    <Link to={`/search-results/${tripId}`} className='relative w-full'>
                                        <button className="btn btn-primary w-full">
                                            Find Matches
                                        </button>
                                    </Link>
                                    <DeleteButton tripId={tripId}/>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <p>No trip data available</p>
                )}
            </div>
        </div>
    );
};

export default TripDetails;
