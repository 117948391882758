import React, { useEffect, useState } from 'react';
import Config from 'assets/Config';

const ProfilePicture = ({ userId, handleImageClick, small, count }) => {
    const className = "relative flex flex-col justify-center items-center "+(small?"w-10":"w-24")+" aspect-square rounded-full bg-blue-500 "+(handleImageClick?"cursor-pointer":"")
    const url = `${Config.storageDomain}/users/${userId}/profile.jpg`;
    const [loading, setLoading] = useState(true);
    const [hide, setHide] = useState(true);
    const [src, setSrc] = useState()
    
    useEffect(()=>{
        setHide(true);
        setLoading(true);
        setSrc(null);
        setTimeout(()=>{
            setSrc(`${url}?refresh=${Date.now()}`);
        },[100])
    },[count]);
    
    const handleErr = ()=>{
        setHide(true);
        setLoading(false);
    }

    const handleLoad = ()=>{
        setHide(false);
        setLoading(false);
    }

    return (
        <div className={className}>
                <img
                    src={src}
                    alt="Profile"
                    className={`rounded-full ${hide && "hidden"}`}
                    onClick={handleImageClick}
                    onLoad={handleLoad}
                    onError={handleErr}
                />
                {loading && <div className="absolute w-[50%] h-[50%] text-white loading z-10"/>}
        </div>
    )
};

export default ProfilePicture;
