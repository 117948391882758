import { useState } from 'react';
import Login from './components/Login';
import Register from './components/Register';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Config from 'assets/Config';
import keys from "assets/keys";

const clientId = (Config.isDev || Config.isLocal)?keys.google.dev:keys.google.prod;

const Auth = () => {
  const [isLogin, setIsLogin] = useState(false); // State to toggle between Login and Register

  return (
    <GoogleOAuthProvider clientId={clientId}>
        <div className="w-full p-[30px]">
        {isLogin ? <Login /> : <Register />}
        
        <p className="mt-4 text-center">
            {isLogin ? (
            <>
                Don't have an account?{' '}
                <button 
                className="text-blue-500 underline"
                onClick={() => setIsLogin(false)}
                >
                Register
                </button>
            </>
            ) : (
            <>
                Already have an account?{' '}
                <button 
                className="text-blue-500 underline"
                onClick={() => setIsLogin(true)}
                >
                Login
                </button>
            </>
            )}
        </p>
        </div>
    </GoogleOAuthProvider>
  )
}

export default Auth;
