import Loader from 'components/Loader';
import logo from 'assets/images/logo.png';

const Loading = ()=>{
    return(
        <div className='min-h-screen flex flex-col items-center justify-center'>
            <img src={logo} alt="Logo" className='m-5' style={{ width: "300px", height: "auto" }} />
            <Loader margin="50" />
        </div>
    )
}

export default Loading