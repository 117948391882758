import { DateTime } from 'luxon';

export const calcNewDateWithLocation = (chosenDate, locationTimeZone) => {
    // Parse the chosen Date object into a Luxon DateTime in the specified time zone
    const localDateTime = DateTime.fromJSDate(chosenDate).setZone(locationTimeZone, { keepLocalTime: true });
    
    // Convert to UTC
    const utcDateTime = localDateTime.toUTC();
  
    // Return the UTC date as a native JavaScript Date object (for server storage)
    return utcDateTime.toJSDate();
}

export const getTimeString = (data) => {
    const timestamp = data.timestamp;
    const timeZone = data.timeZoneId;

    // Create a Luxon DateTime object from the timestamp in the given time zone
    const dateTimeInZone = DateTime.fromMillis(timestamp, { zone: timeZone });
    
    // Format the date and time for display (adjust format as needed)
    return `${dateTimeInZone.toFormat("dd-MM-yyyy")}, ${dateTimeInZone.toFormat("HH:mm a")} (${timeZone})`;
}