import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNotifications } from 'utils/NotificationsProvider';
import logo from 'assets/images/logo.png';

const Button = ({ to, label, onClick, variant = 'outline', fullWidth = false, isActive, setIsMenuOpen, hasUnreadMessages }) => {
  const baseClasses = `btn ${variant ? 'btn-' + variant : 'btn-outline'} ${fullWidth ? 'w-full' : ''} ${isActive ? 'btn-active' : ''}`;

  const handleClick = () => {
    if (onClick) onClick();
    if (setIsMenuOpen) setIsMenuOpen(false);
  };

  return (
    <Link to={to} onClick={handleClick} className="relative">
      <button className={baseClasses}>
        {label}
        {hasUnreadMessages && (
          <span className="absolute -top-1 -right-1 block w-4 h-4 rounded-full ring-2 ring-white bg-red-600"></span>
        )}
      </button>
    </Link>
  );
};

const MenuButton = ({isMenuOpen, setIsMenuOpen, hasUnreadMessages})=>{
    return(
        <button
            className="block md:hidden text-gray-800 focus:outline-none relative"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
            <div className="flex flex-col justify-between w-[26px] h-[20px] relative">
                <span
                className={`block h-[2px] bg-current transition-transform duration-300 ease-in-out transform transform-origin-center ${
                    isMenuOpen ? 'rotate-45 translate-y-[9px]' : ''
                }`}
                />
                <span
                className={`block h-[2px] bg-current transition-opacity duration-300 ease-in-out ${
                    isMenuOpen ? 'opacity-0' : ''
                }`}
                />
                <span
                className={`block h-[2px] bg-current transition-transform duration-300 ease-in-out transform transform-origin-center ${
                    isMenuOpen ? '-rotate-45 -translate-y-[9px]' : ''
                }`}
                />
            </div>
        {hasUnreadMessages && (
            <span className="absolute -top-1 -right-2 block w-3 h-3 rounded-full bg-red-600" />
        )}
        </button>
    )
}

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { hasUnreadMessages = false, notifications } = useNotifications() || {};
  const location = useLocation();

    const menuItems = <>
        <Button to="/" label="Home" isActive={location.pathname === '/'} setIsMenuOpen={setIsMenuOpen} hasUnreadMessages={notifications.length > 0} />
        <Button to="/my-profile" label="Profile" isActive={location.pathname === '/my-profile'} setIsMenuOpen={setIsMenuOpen} />
        <Button to="/my-trips" label="My Trips" isActive={location.pathname === '/my-trips'} setIsMenuOpen={setIsMenuOpen} />
        <Button to="/chats" label="Chats" isActive={location.pathname === '/chats'} setIsMenuOpen={setIsMenuOpen} hasUnreadMessages={hasUnreadMessages} />
        <Button to="/create-trip" label="New Trip" variant="primary" isActive={location.pathname === '/create-trip'} setIsMenuOpen={setIsMenuOpen} />
    </>

  return (
    <div className="bg-white shadow z-40 sticky top-0">
      <div className="max-w-7xl mx-auto px-4 py-6 flex justify-between items-center">
        <div className="relative">
            <Link to="/" onClick={() => setIsMenuOpen(false)} className="cursor-pointer">
                <img src={logo} alt="Logo" className="w-[100px] sm:w-[200px] lg:w-[200px] h-auto"/>
            </Link>
        </div>

        {/* Hamburger icon with animation */}
        <MenuButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} hasUnreadMessages={hasUnreadMessages}/>

        {/* Desktop menu */}
        <div className="hidden md:flex space-x-4">{menuItems}</div>

        {/* Mobile menu */}
        <div
          className={`md:hidden absolute bottom-1 translate-y-full left-0 right-0 bg-white shadow-lg z-10 transition-max-height duration-500 ease-in-out overflow-hidden ${
            isMenuOpen ? 'max-h-[1000px] p-4' : 'max-h-0'
          }`}
        >
          <div className="flex flex-col items-center space-y-4">{menuItems}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
