import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Firebase Storage imports
import { getAuth } from 'firebase/auth'; // Firebase Auth to get the current user

const ImageCropper = ({ image, onCropComplete, onCancel }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // Loading state to disable buttons

  // Function to create a cropped image in 300x300 px dimensions
  const getCroppedImg = async () => {
    const createImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener('load', () => resolve(img));
        img.addEventListener('error', (error) => reject(error));
        img.setAttribute('crossOrigin', 'anonymous'); // Avoid CORS issues
        img.src = url;
      });
    };

    const imageElement = await createImage(image);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const width = 300; // Desired output width
    const height = 300; // Desired output height
    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(
      imageElement,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      width,
      height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve(file); // Return the Blob directly for Firebase upload
      }, 'image/jpeg');
    });
  };

  // Handle saving the cropped image and uploading to Firebase
  const handleSave = async () => {
    setIsUploading(true); // Set loading state to true
    const croppedBlob = await getCroppedImg(); // Get the cropped image blob

    const auth = getAuth(); // Get current authenticated user
    const user = auth.currentUser;
    const storage = getStorage();
    const storageRef = ref(storage, `users/${user.uid}/profile.jpg`); // Path to store the profile image

    // Upload the Blob to Firebase Storage
    const uploadTask = uploadBytesResumable(storageRef, croppedBlob);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Optionally, you can track upload progress here
      },
      (error) => {
        console.error('Upload failed:', error);
        setIsUploading(false); // Reset loading state if failed
      },
      () => {
        onCropComplete(); // Send the download URL to parent component
        setIsUploading(false); // Reset loading state after completion
      }
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-md shadow-lg w-[300px]">
        <div className="relative w-full h-60">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1} // Square aspect ratio
            cropShape="round" // Round mask
            showGrid={false} // Hide grid
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={(_, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
          />
        </div>
        <div className="flex flex-col items-center mt-4">
          <input
            type="range"
            min="1"
            max="3"
            step="0.1"
            value={zoom}
            onChange={(e) => setZoom(e.target.value)}
            className="w-full"
          />
          <button
            onClick={handleSave}
            className={`btn btn-primary mt-4 w-full ${isUploading ? 'opacity-50' : ''}`}
            disabled={isUploading} // Disable button while uploading
          >
            {isUploading ? 'Uploading...' : 'Save'}
          </button>
          <button
            onClick={onCancel}
            className="btn btn-secondary mt-2 w-full"
            disabled={isUploading} // Disable cancel button during upload
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
