// MatchView.js
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import SearchResultItem from '../SearchResults/SearchResultItem';
import TitleComponent from '../TripDetails/components/TitleComponent';
import {calculateRouteOverlap, calculateTimeMatch, getUserInfo} from "../SearchResults/components/searchTrips";
import Loader from 'components/Loader';

const MatchView = () => {
    const { tripID, matchID } = useParams();
    const [loading, setLoading] = useState(true);
    const [match, setMatch] = useState();
    const [tripData, setTripData] = useState();
    const db = getFirestore();

    useEffect(()=>{
        const init = async()=>{
            const tripDoc = await getDoc( doc(db, 'trips', tripID) );
            const matchDoc = await getDoc( doc(db, 'trips', matchID) );
            const tripData = {
                ...tripDoc.data(),
                id: tripID
            }
            const matchData = matchDoc.data();
            const routeOverlap = calculateRouteOverlap(tripData, matchData);
            const timeMatch = calculateTimeMatch(tripData, matchData);
            const matchName = await getUserInfo(matchData.uid);
            const match = {
                ...matchData,
                name: matchName,
                routeOverlap,
                timeMatch,
                tripDetails: { id: matchID, ...matchData }
            }
            setMatch(match);
            setTripData(tripData);
            setLoading(false);
        }
        init();
    },[])

    // render
    if(loading) return <Loader/>
    return (
        <div className="w-full max-w-[800px] m-10 mx-auto bg-white p-6 rounded-lg shadow-md">
            <div className="cursor-pointer hover:bg-gray-100" onClick={()=>{window.open("/trip-details/"+tripID,"_blank")}}>
                <div className="text-2xl text-center font-bold underline">
                    Match for trip
                </div>
                <TitleComponent tripData={tripData} />
            </div>
            <SearchResultItem match={match} tripData={tripData} expand={true} />
        </div>
    )
}

export default MatchView;
