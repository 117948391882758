import Config from 'assets/Config';
import logo from 'assets/images/logo.png';

const Logo = ({width})=>{
    const handleClick = ()=>{
        const url = `https://${(Config.isLocal || Config.isDev)?"dev.":""}ride-share.app`;
        window.open(url,"_blank");
    }
    return(
        <div onClick={handleClick} className='cursor-pointer'>
            <img src={logo} alt="Logo" className='m-5' style={{ width: (width?width:"300")+"px", height: "auto" }} />
        </div>
    )
}

export default Logo