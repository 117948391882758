import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import Loader from 'components/Loader';

// ToggleField Component
const ToggleField = ({ label, name, checked, onChange, updating }) => (
  <div className="form-control mb-4">
    <label className="cursor-pointer label justify-between">
      <span className="label-text">{label}</span>
      <input
        type="checkbox"
        name={name}
        className="toggle toggle-primary"
        checked={checked}
        onChange={onChange}
        disabled={updating}
      />
    </label>
  </div>
);

// NotificationSection Component
const NotificationSection = ({ title, settings, sectionKey, handleChange, updating }) => (
  <section className="mb-6">
    <h2 className="text-xl font-semibold mb-4">{title} Notifications</h2>
    <ToggleField
      label="Email"
      name={`${sectionKey}.email`}
      checked={settings.email}
      onChange={handleChange}
      updating={updating[`${sectionKey}.email`]}
    />
    <ToggleField
      label="In-app"
      name={`${sectionKey}.inapp`}
      checked={settings.inapp}
      onChange={handleChange}
      updating={updating[`${sectionKey}.inapp`]}
    />
  </section>
);

// Settings Component
const Settings = () => {
  const [settings, setSettings] = useState({
    chat: { inapp: false, email: false },
    tripMatches: { inapp: false, email: false },
    reviews: { inapp: false, email: false },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [updating, setUpdating] = useState({});

  const auth = getAuth(); // Firebase auth
  const db = getFirestore(); // Firestore instance

  // Fetch user's notification settings from Firestore
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setSettings(userData.notifSettings || {
              chat: { inapp: false, email: false },
              tripMatches: { inapp: false, email: false },
              reviews: { inapp: false, email: false },
            });
          }
        }
        setLoading(false);
      } catch (err) {
        setError("Failed to load notification settings.");
        setLoading(false);
      }
    };
    fetchSettings();
  }, [auth, db]);

  // Handle notification settings change
  const handleChange = async (e) => {
    const { name, checked } = e.target;
    const [section, type] = name.split(".");

    const updatedSettings = {
      ...settings,
      [section]: { ...settings[section], [type]: checked },
    };

    setUpdating((prevState) => ({ ...prevState, [name]: true }));

    try {
      const user = auth.currentUser;
      if (user) {
        await updateDoc(doc(db, "users", user.uid), {
          notifSettings: updatedSettings,
        });
        setSettings(updatedSettings);
      }
    } catch (err) {
      setError("Failed to update notification settings.");
    } finally {
      setUpdating((prevState) => ({ ...prevState, [name]: false }));
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="container mx-auto p-6 w-full max-w-lg">
      <h1 className="text-3xl font-bold mb-8">Notification Settings</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}

      {/* Chat Notifications */}
      <NotificationSection
        title="Chat"
        sectionKey="chat"
        settings={settings.chat}
        handleChange={handleChange}
        updating={updating}
      />

      {/* Trip Matches Notifications */}
      <NotificationSection
        title="Trip Matches"
        sectionKey="tripMatches"
        settings={settings.tripMatches}
        handleChange={handleChange}
        updating={updating}
      />

      {/* Reviews Notifications */}
      <NotificationSection
        title="Reviews"
        sectionKey="reviews"
        settings={settings.reviews}
        handleChange={handleChange}
        updating={updating}
      />
    </div>
  );
};

export default Settings;
