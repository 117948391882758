import React, { useState, useEffect } from 'react';
import { getFirestore, collection, doc, getDoc, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import ProfilePicture from "routes/app/components/ProfilePicture"
import Loader from 'components/Loader'; // Import the Loader component
import { Link } from 'react-router-dom';

const Rating = ({ rating, setRating, disabled }) => {
  return (
    <div className="rating">
      {[...Array(5)].map((_, index) => (
        <input
          key={index}
          type="radio"
          name="rating"
          className={`mask mask-star-2 bg-yellow-500 ${disabled ? 'cursor-not-allowed' : ''}`}
          checked={rating === index + 1}
          onChange={() => !disabled && setRating(index + 1)} // Disable interaction when disabled
          disabled={disabled} // Disable input when isSubmitting is true
        />
      ))}
    </div>
  );
};

const ReviewPage = () => {
  const { userId } = useParams(); // Get userId from the router
  const [rating, setRating] = useState(5);
  const [reviewText, setReviewText] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const [isSelfReview, setIsSelfReview] = useState(false); // To track if the user is trying to review themselves
  const [userExists, setUserExists] = useState(true); // To track if the user being reviewed exists
  const [isLoading, setIsLoading] = useState(true); // New state for loading
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for form submission
  const [userName, setUserName] = useState(''); // New state for storing user name
  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const currentUser = auth.currentUser;

    // Check if the user being reviewed exists in Firestore and fetch user name
    const checkUserExists = async () => {
      setIsLoading(true); // Start loading
      try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (!userDoc.exists()) {
          setUserExists(false);
        } else {
          setUserExists(true);
          const userData = userDoc.data();
          setUserName(userData.name || 'Unknown User'); // Fetch the user's name

          if (currentUser && currentUser.uid === userId) {
            setIsSelfReview(true); // Prevent self-review
          } else {
            setIsSelfReview(false);
          }
        }
      } catch (error) {
        console.error('Error checking user existence:', error);
      } finally {
        setIsLoading(false); // Stop loading
      }
    };

    checkUserExists();
  }, [auth, userId, db]);

  const submitReview = async () => {
    const currentUserId = auth.currentUser.uid;
    setIsSubmitting(true); // Start form submission

    try {
      await addDoc(collection(db, `users/${userId}/reviews`), {
        rating,
        reviewText,
        userId: currentUserId,
        timestamp: new Date(),
      });
      setRating(0);
      setReviewText('');
      handleReviewSubmitted();
    } catch (error) {
      console.error('Error adding review: ', error);
    } finally {
      setIsSubmitting(false); // End form submission
    }
  };

  const handleReviewSubmitted = () => {
    setSuccessMessage(true);
    setReviewSubmitted(true);
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-white shadow-lg rounded">
      {isLoading ? (
        <Loader /> // Display the loader while checks are being performed
      ) : !userExists ? (
        <div className="alert alert-error">
          The user you are trying to review does not exist.
        </div>
      ) : isSelfReview ? (
        <div className="alert alert-warning">
          You cannot review yourself.
        </div>
      ) : (
        <>
          {/* Success Message */}
          {successMessage && (
            <div className="alert alert-success text-white mb-4">
              Your review has been submitted successfully!
            </div>
          )}

          {!reviewSubmitted && (
            <>
              <div className='flex flex-col items-center justify-center mb-5'>
                <Link to={"/profile/"+userId} className="cursor-pointer">
                    <ProfilePicture userId={userId}/>
                </Link>
                <h1 className="text-3xl font-bold">Submit a Review for {isLoading ? '...' : userName}</h1>
                
            </div>
            {/* Rating Component */}
              <div className="mb-4 flex flex-row justify-start">
                <span className="font-semibold mr-2">Rating:</span>
                <Rating rating={rating} setRating={setRating} disabled={isSubmitting} />
              </div>

              {/* Review Form */}
              <div className="mb-4">
                <label className="block text-lg font-medium mb-2">Review:</label>
                <textarea
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                  className="textarea textarea-bordered w-full"
                  placeholder="Write your review here..."
                  disabled={isSubmitting} // Disable textarea when submitting
                ></textarea>
              </div>

              {/* Submit Button */}
              <button
                className={`btn btn-primary ${isSubmitting ? 'loading' : ''}`}
                onClick={submitReview}
                disabled={rating === 0 || reviewText.trim() === '' || isSubmitting} // Disable button during submission
              >
                {isSubmitting ? 'Submitting...' : 'Submit Review'}
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ReviewPage;