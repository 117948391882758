import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import searchTrips from './components/searchTrips';
import SearchResultItem from './SearchResultItem';
import Loader from 'components/Loader';
import TitleComponent from "../TripDetails/components/TitleComponent";
import mainImg from 'assets/images/search/secondary.jpg';

const NoResults = ()=>{
    return(
        <div className="flex flex-col items-center m-10 max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg text-center">
                <img src={mainImg} className='w-[200px] rounded-full'/>
                <p className="text-lg text-gray-700 my-4">
                    No matches found for this trip :/
                </p>
                <p className="text-gray-600 text-xl font-semibold">
                    But don’t worry!<br/>
                    When people post trips that match yours, you’ll be notified via email and right here on your home page.
                </p>
        </div>
    )
}

const SearchResults = () => {
    const { tripId } = useParams(); // Get the tripId from the URL
    const [searchResults, setSearchResults] = useState([]);
    const [tripData, setTripData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sortOption, setSortOption] = useState('average'); // Default sorting by average

    const db = getFirestore();

    useEffect(() => {
        const fetchTripData = async () => {
            setLoading(true);
            try {
                // Fetch current trip details
                const tripDocRef = doc(db, 'trips', tripId);
                const tripDoc = await getDoc(tripDocRef);

                if (tripDoc.exists()) {
                    const currentTrip = tripDoc.data();
                    currentTrip.id = tripId;
                    setTripData(currentTrip);

                    // Search for matching trips using the query logic
                    const matches = await searchTrips(currentTrip);
                    
                    setSearchResults(matches);
                } else {
                    console.error('Trip not found');
                }
            } catch (error) {
                console.error('Error fetching trip or search results:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTripData();
    }, [tripId, db]);

    const sortResults = (results, option) => {
        return results.sort((a, b) => {
            if (option === 'route') {
                return b.routeOverlap - a.routeOverlap; // Sort by route match (descending)
            } else if (option === 'time') {
                return b.timeMatch - a.timeMatch; // Sort by time match (descending)
            } else {
                const avgA = (a.routeOverlap + a.timeMatch) / 2;
                const avgB = (b.routeOverlap + b.timeMatch) / 2;
                return avgB - avgA; // Sort by average of route and time match (descending)
            }
        });
    };

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
    };

    if (loading) return <Loader />;

    if (!searchResults || searchResults.length === 0) return <NoResults/>

    const sortedResults = sortResults([...searchResults], sortOption);

    return (
        <div className="min-h-screen flex flex-col items-center p-8">
            <Link to={`/trip-details/${tripId}`} className='cursor-pointer'>
                <TitleComponent tripData={tripData}/>
            </Link>
            <div className='w-[300px] h-[5px] rounded-full bg-blue-950 mb-10'/>
            <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
                <div className="mb-4">
                    <label htmlFor="sortOption" className="mr-2">Sort by:</label>
                    <select
                        id="sortOption"
                        value={sortOption}
                        onChange={handleSortChange}
                        className="p-2 border rounded"
                    >
                        <option value="average">Average (Route & Time)</option>
                        <option value="route">Route Match</option>
                        <option value="time">Time Match</option>
                    </select>
                </div>
                <ul>
                    {sortedResults.map((match) => (
                        <SearchResultItem key={match.tripDetails.id} match={match} tripData={tripData} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SearchResults;
